import axios from "axios"
import moment from "moment"
import {Types} from "../types/types"
import Decimal from "decimal.js"

export async function getBanksReport(fromMoment: moment.Moment, toMoment: moment.Moment): Promise<Types.AccountsBanksReport> {
  const fromTm = fromMoment.unix();
  const toTm = toMoment.unix();
  const { data } = await axios.get(`/api/v1/accounts/report/by-bank?fromTm=${fromTm}&toTm=${toTm}`);

  return data;
}

export async function getCurrenciesReport(fromMoment: moment.Moment, toMoment: moment.Moment): Promise<Types.AccountsCurrenciesReport> {
  const fromTm = fromMoment.unix();
  const toTm = toMoment.unix();
  const { data } = await axios.get(`/api/v1/accounts/report/by-currency?fromTm=${fromTm}&toTm=${toTm}`);

  return data;
}

export async function listAccounts(): Promise<Types.Account[]> {
  const { data } = await axios.get("/api/v1/accounts");

  return data;
}

export async function setAccountBalance(accountId: number, balance: Decimal, balanceMoment: moment.Moment): Promise<void> {
  const balanceTm = balanceMoment.unix();
  const { data } = await axios.post(`/api/v1/accounts/${accountId}/balances`, {
    balance: balance,
    balanceTm: balanceTm
  });

  return data;
}

export async function setAccountName(accountId: number, accountName: string): Promise<void> {
  const { data } = await axios.put(`/api/v1/accounts/${accountId}/name`, {
    name: accountName,
  });

  return data;
}