<script setup type="ts">
  import NavBar from "./NavBarView.vue";
  import VueDatePicker from "@vuepic/vue-datepicker";
  import "@vuepic/vue-datepicker/dist/main.css";
</script>

<template>
  <NavBar />
  <div class="filtersouter">
    <VueDatePicker month-picker range v-model="dates" @update:model-value="onDatesUpdate" lang="en" />
  </div>
  <h1>By bank</h1>
  <Bar
    v-if="byBankLoaded"
    :height="100"
    :options="chartOptions"
    :data="byBankChartData"
  />
  <h1>By currency</h1>
  <Bar
    v-if="byCurrencyLoaded"
    :height="100"
    :options="chartOptions"
    :data="byCurrencyChartData"
  />
</template>

<script type="ts">
  import moment from "moment"
  import colorLib from "@kurkle/color";
  import { getBanksReport, getCurrenciesReport } from "../services/accounts";
  import { Bar } from "vue-chartjs"
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, LineController} from "chart.js"

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, LineController)

  export default {
    name: "BarChart",
    components: { Bar },
    data() {
      return {
        colors: ["#a83232", "#3832a8", "#3281a8", "#a8327d", "#5da832", "#5332a8", "#3271a8", "#8c32a8", "#a83263", "#9ea832", "#a83294", "#32a875", "#a87f32", "#3244a8", "#3ea832", "#a83232", "#79a832", "#7b32a8", "#32a898", "#a86732", "#3259a8", "#6932a8", "#a89832", "#a432a8", "#329ea8", "#a84832", "#a8324a"],
        byBankLoaded: false,
        byCurrencyLoaded: false,
        dates: [],
        byBankChartData: {
          labels: [],
          datasets: []
        },
        byCurrencyChartData: {
          labels: [],
          datasets: []
        },
        chartOptions: {
          responsive: true,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true
            }
          },
          plugins: {
            legend: {
              display: true,
              position: "bottom",
              align: "start",
              onClick: this.onLegendClick
            }
          }
        }
      }
    },
    methods: {
      transparentize(value, opacity) {
        var alpha = opacity === undefined ? 0.5 : 1 - opacity;
        return colorLib(value).alpha(alpha).rgbString();
      },
      onLegendClick(e, legendItem, legend) {
        const index = legendItem.datasetIndex;
        const ci = legend.chart;
        if (ci.isDatasetVisible(index)) {
          ci.hide(index);
          legendItem.hidden = true;
          for (let i in ci.data.datasets[index].data) {
            ci.data.datasets[0].data[i] -= ci.data.datasets[index].data[i];
          }
        } else {
          for (let i in ci.data.datasets[index].data) {
            ci.data.datasets[0].data[i] += ci.data.datasets[index].data[i];
          }
          ci.show(index);
          legendItem.hidden = false;
        }  
      },
      onDatesUpdate(dates) {
        this.byBankLoaded = false;
        const fromMoment = moment(dates[0]).startOf("month").startOf("day");
        const toMoment = moment(dates[1]).endOf("month").endOf("day");

        getBanksReport(fromMoment, toMoment).then((response) => {
          this.byBankChartData.labels = response.dates;
          
          this.byBankChartData.datasets = [];

          for (let i in response.banksStat) {
            let values = [];
            for (let dateStat of response.banksStat[i].datesStat) {
              values.push(dateStat);
            }
            this.byBankChartData.datasets.push({
              label: response.banksStat[i].bank.name,
              data: values,
              borderColor: this.colors[i % this.colors.length],
              backgroundColor: this.transparentize(this.colors[i % this.colors.length], 0.5),
              borderWidth: 0.5,
              borderRadius: 5,
              borderSkipped: false,
              order: i + 1,
              hidden: false
            });
          }

          this.byBankLoaded = true;
        });

        getCurrenciesReport(fromMoment, toMoment).then((response) => {
          this.byCurrencyChartData.labels = response.dates;
          
          this.byCurrencyChartData.datasets = [];

          for (let i in response.currenciesStat) {
            let values = [];
            for (let dateStat of response.currenciesStat[i].datesStat) {
              values.push(dateStat);
            }
            this.byCurrencyChartData.datasets.push({
              label: response.currenciesStat[i].currency.symbol,
              data: values,
              borderColor: this.colors[i % this.colors.length],
              backgroundColor: this.transparentize(this.colors[i % this.colors.length], 0.5),
              borderWidth: 0.5,
              borderRadius: 5,
              borderSkipped: false,
              order: i + 1,
              hidden: false
            });
          }

          this.byCurrencyLoaded = true;
        })
      }
    },
    mounted() {
      const fromMoment = moment(new Date()).subtract(11, "months").startOf("month").startOf("day");
      const toMoment = moment(new Date()).endOf("month").endOf("day");

      this.dates = [
        {
          year: fromMoment.year(),
          month: fromMoment.month()
        },
        {
          year: toMoment.year(),
          month: toMoment.month()
        }
      ];
      this.onDatesUpdate(this.dates);
    }
  }
</script>

<style>
.filtersouter {
  width: 250px;
}
</style>
