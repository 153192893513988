<template>
  <!-- <div class="app_menu">
    <img  class="logout" src="/icons/logout.svg" />
  </div> -->
  <div class="navbar">
    <a @click="logout" href="#">Logout</a>
    <div class="dropdown">
      <button class="dropbtn">Accounts<i class="fa fa-caret-down"></i></button>
      <div class="dropdown-content">
        <RouterLink to="/accounts/analytics">Analytics</RouterLink>
        <RouterLink to="/accounts">Details</RouterLink>
      </div>
    </div>
    <div class="dropdown">
      <button class="dropbtn">Transactions<i class="fa fa-caret-down"></i></button>
      <div class="dropdown-content">
        <RouterLink to="/">Analytics</RouterLink>
        <RouterLink to="/transactions/report">Report</RouterLink>
        <RouterLink to="/transactions/history">History</RouterLink>
        <RouterLink to="/categories">Categories</RouterLink>
      </div>
    </div>
  </div>
</template>

<script type="ts">
  import router from "@/router"
  import {logout} from "../services/login";

   export default {
    methods: {
      logout(event) {
        if (event) {
          logout();
          router.push({name: "login-step-1"});
        }
      }
    }
  }
</script>

<style>
  .navbar {
    overflow: hidden;
  }

  .navbar a {
    float: right;
    font-size: 1.5em;
    text-decoration: none;
    color: inherit;
    text-align: center;
    padding: 5px 10px 5px;
  }

  .dropdown {
    float: right;
    overflow: hidden;
  }

  .dropdown .dropbtn {
    font-size: 1.5em;
    text-decoration: none;
    color: inherit;
    border: none;
    outline: none;
    padding: 5px 10px 5px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  .dropdown-content a {
    float: none;
    color: inherit;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }
</style>