<script setup type="ts">
  import NavBar from "./NavBarView.vue";
  import VueDatePicker from "@vuepic/vue-datepicker";
  import "@vuepic/vue-datepicker/dist/main.css";
</script>

<template>
  <NavBar />
  <div class="filtersouter">
    <VueDatePicker month-picker range v-model="dates" @update:model-value="onDatesUpdate" lang="en" />
  </div>
  <div id="spinner">
    <img src="/icons/spinner.svg" alt="Loading" />
  </div>
  <div id="tableouter">
    <table>
      <tbody>
        <tr>
          <th>
            Date and time
          </th>
          <th>
            Bank
          </th>
          <th>
            Amount
          </th>
          <th>
            Merchant
          </th>
          <th>
            Category
          </th>
          <th>
            Actions
          </th>
        </tr>
        <tr v-for="transaction in transactions" v-bind:key="transaction.transactionId">
          <td>{{ new Date(transaction.transactionTm * 1000).toLocaleString() }}</td>
          <td>{{ transaction.bank.name }}</td>
          <td>
            <span v-if="transaction.amount.value > 0" class="positive bold">+</span>
            <span v-else class="negative bold">-</span>{{ Math.abs(transaction.amount.value) }} {{ transaction.amount.currency.symbol }}
            
            <span v-if="transaction.secondaryAmount != null && transaction.secondaryAmount.currency.code !== transaction.amount.currency.code">
              ({{ Math.floor(Math.abs(transaction.secondaryAmount.value) * 100) / 100 }} {{ transaction.secondaryAmount.currency.symbol }})
            </span>
            <img v-else-if="transaction.secondaryAmount == null" class="clickable right icon" src="/icons/attention.svg" alt="Attention">
          </td>
          <td>{{ transaction.merchant.name }}</td>
          <td>
            <div class="current">
              <span class="category">{{ transaction.category.name }}</span>
              <img @click="showSelector" class="clickable right icon" src="/icons/edit.svg" alt="Edit">
            </div>
            <div class="selector">
              <select v-model="categoryId">
                <option v-for="category in categories" v-bind:value="category.id" v-bind:key="category.id">{{ category.name }}</option>
              </select>
              <button @click="saveForMerchant($event, transaction.transactionId)">Apply for merchant</button>
              <button @click="saveForTransaction($event, transaction.transactionId)">Apply for transaction</button>
              <button @click="hideSelector">Cancel</button>
            </div>
          </td>
          <td>
            <img @click="hideTransaction($event, transaction.transactionId)" class="clickable center icon" src="/icons/eye-crossed.svg" alt="Hide">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script type="ts">
  import moment from "moment"
  import {listTransactions} from "../services/transactions";
  import {setTransactionCategory} from "../services/transactions";
  import {setTransactionHidden} from "../services/transactions";
  import {setMerchantCategory} from "../services/transactions";
  import {listCategories} from "../services/categories";

  export default {
    data() {
      return {
        transactions: [],
        categories: [],
        categoryId: "",
        dates: []
      }
    },
    methods: {
      showSelector(event) {
        if (event) {
          event.target.parentElement.style.display = "none";
          event.target.parentElement.parentElement.querySelector(".selector").style.display = "block";
        }
      },
      hideSelector(event) {
        if (event) {
          event.target.parentElement.style.display = "none";
          event.target.parentElement.parentElement.querySelector(".current").style.display = "block";
        }
      },
      saveForMerchant(event, transactionId) {
        if (event) {
          setMerchantCategory(transactionId, this.categoryId).then(() => {
            this.init();
            this.hideSelector();
          })
        }
      },
      saveForTransaction(event, transactionId) {
        if (event) {
          setTransactionCategory(transactionId, this.categoryId).then((response) => {
            event.target.parentElement.parentElement.querySelector(".current").querySelector(".category").textContent = response.category.name;
            this.hideSelector(event);
          })
        }
      },
      hideTransaction(event, transactionId) {
        if (event) {
          setTransactionHidden(transactionId, true).then(() => {
            event.target.parentElement.parentElement.style.display = "none";
          })
        }
      },
      onDatesUpdate(dates) {
        document.getElementById("tableouter").style.display = "none";
        document.getElementById("spinner").style.display = "block";

        const fromMoment = moment(dates[0]).startOf("month").startOf("day");
        const toMoment = moment(dates[1]).endOf("month").endOf("day");

        listTransactions(fromMoment, toMoment).then((response) => {
          this.transactions = response;
          document.getElementById("spinner").style.display = "none";
          document.getElementById("tableouter").style.display = "block";
        })
      },
      init() {
        const fromMoment = moment(new Date()).subtract(1, "months").startOf("month").startOf("day");
        const toMoment = moment(new Date()).endOf("month").endOf("day");

        this.dates = [
          {
            year: fromMoment.year(),
            month: fromMoment.month()
          },
          {
            year: toMoment.year(),
            month: toMoment.month()
          }
        ];
        this.onDatesUpdate(this.dates);

        listCategories().then((response) => {
          this.categories = response;
        })
      }
    },
    mounted() {
      this.init();
    }
  }
</script>

<style>
#spinner {
  width: 100%;
  height: 100px;
}
#spinner img {
  display:block;
  margin-left:auto;
  margin-right:auto;
  width: 100px;
}
.filtersouter {
  width: 250px;
}
#tableouter {
  min-width: 100%;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: none;
}
table {
  border-collapse: collapse;
  width: 100%;
}
table th {
  border: solid 1px #cad4de;
  padding: 5px;
}
table td {
  border: solid 1px #cad4de;
  padding: 5px;
}
.positive {
  color: #49ba5d;
}
.negative {
  color: #fa1814;
}
.bold {
  font-weight: 900;
}
.current {
  display: block;
  width: 100%;
}
.clickable {
  cursor: pointer;
}
.right {
  float: right;
}
.icon {
  width: 1em;
  height: 1em;
}
.center {
  display:block;
  margin-left: auto;
  margin-right: auto;
}
.selector {
  display: none;
  width: 100%;
}
.selector select {
  width: 100%;
}
.selector button {
  width: 100%;
}
</style>
