import { createRouter, createWebHistory } from "vue-router";
import AccountsAnalyticsView from "@/components/AccountsAnalyticsView.vue";
import AccountsView from "@/components/AccountsView.vue";
import CategoriesView from "@/components/CategoriesView.vue";
import LoginStep1View from "@/components/LoginStep1View.vue";
import LoginStep2View from "@/components/LoginStep2View.vue";
import RegistrationView from "@/components/RegistrationView.vue";
import TransactionsAnalyticsView from "@/components/TransactionsAnalyticsView.vue";
import TransactionsReportView from "@/components/TransactionsReportView.vue";
import TransactionsView from "@/components/TransactionsView.vue";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    { path: "/", name: "analytics", component: TransactionsAnalyticsView },
    { path: "/transactions/report", name: "report", component: TransactionsReportView },
    { path: "/transactions/history", name: "transactions", component: TransactionsView },
    
    { path: "/accounts/analytics", name: "accounts-analytics", component: AccountsAnalyticsView },
    { path: "/accounts", name: "accounts", component: AccountsView },

    { path: "/registration/:attemptUuid", name: "registration", component: RegistrationView, props: (route) => ({ attemptUuid: route.params.attemptUuid })},

    { path: "/login-step-1", name: "login-step-1", component: LoginStep1View },
    { path: "/login-step-2/:attemptUuid", name: "login-step-2", component: LoginStep2View, props: (route) => ({ attemptUuid: route.params.attemptUuid })},
    
    { path: "/categories", name: "categories", component: CategoriesView },
  ]
});

export default router;
