<script setup type="ts">
  import NavBar from "./NavBarView.vue";
</script>

<template>
  <NavBar />
  <div id="spinner">
    <img src="/icons/spinner.svg" alt="Loading" />
  </div>
  <div id="tableouter">
    <table>
      <tbody>
        <tr>
          <th>
            Bank
          </th>
          <th>
            Account name
          </th>
          <th>
            Balance
          </th>
          <th>
            Balance date
          </th>
        </tr>
        <tr v-for="account in accounts" v-bind:key="account.id">
          <td>{{ account.bank.name }}</td>
          <td>
            <div class="view">
              {{ account.bankAccountName }}
              <img @click="showEdit" class="clickable right icon" src="/icons/edit.svg" alt="Edit">
            </div>
            <div class="edit">
              <input :id="'name_' + account.id" type="text" :value="account.bankAccountName" />
              <button @click="setName($event, account.id)">Save</button>
              <button @click="hideEdit">Cancel</button>
            </div>
          </td>
          <td>
            <div class="view">
              {{ account.balance.value }} {{ account.balance.currency.symbol }}
              <span v-if="account.secondaryBalance != null && account.secondaryBalance.currency.code !== account.balance.currency.code">
                ({{ Math.floor(account.secondaryBalance.value * 100) / 100 }} {{ account.secondaryBalance.currency.symbol }})
              </span>
              <img @click="showEdit" class="clickable right icon" src="/icons/edit.svg" alt="Edit">
            </div>
            <div class="edit">
              <input :id="'balance_' + account.id" type="number" step="0.01" :value="account.balance.value" />
              <button @click="setBalance($event, account.id)">Save</button>
              <button @click="hideEdit">Cancel</button>
            </div>
          </td>
          <td>{{ new Date(account.balanceTm * 1000).toLocaleString() }}</td>
        </tr>
        <tr>
          <td colspan="2"></td>
          <td>{{ Math.floor(totalSecondaryBalance.value * 100) / 100 }} {{ totalSecondaryBalance.currency.symbol }}</td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script type="ts">
  import moment from "moment"
  import {listAccounts, setAccountBalance, setAccountName} from "../services/accounts";

  export default {
    data() {
      return {
        accounts: [],
        totalSecondaryBalance: {
          value: 0,
          currency: {
            code: "EUR",
            symbol: "€"
          }
        }
      }
    },
    methods: {
      init() {
        listAccounts().then((response) => {
          this.accounts = response.accounts;
          this.totalSecondaryBalance = response.totalSecondaryBalance;
          
          document.getElementById("spinner").style.display = "none";
          document.getElementById("tableouter").style.display = "block";
        })
      },
      showEdit(event) {
        if (event) {
          event.target.parentElement.style.display = "none";
          event.target.parentElement.parentElement.querySelector(".edit").style.display = "block";
        }
      },
      hideEdit(event) {
        if (event) {
          event.target.parentElement.style.display = "none";
          event.target.parentElement.parentElement.querySelector(".view").style.display = "block";
        }
      },
      setBalance(event, accountId) {
        if (event) {
          let now = moment(new Date())
          let balance = document.getElementById("balance_" + accountId).value;
          setAccountBalance(accountId, balance, now).then(() => {
            this.hideEdit(event);
            this.init();
          });
        }
      },
      setName(event, accountId) {
        if (event) {
          let name = document.getElementById("name_" + accountId).value;
          setAccountName(accountId, name).then(() => {
            this.init();
            this.hideEdit(event);
          });
        }
      }
    },
    mounted() {
      this.init();
    }
  }
</script>

<style>
#spinner {
  width: 100%;
  height: 100px;
}
#spinner img {
  display:block;
  margin-left:auto;
  margin-right:auto;
  width: 100px;
}
#tableouter {
  min-width: 100%;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: none;
}
table {
  border-collapse: collapse;
  width: 100%;
}
table th {
  border: solid 1px #cad4de;
  padding: 5px;
}
table td {
  border: solid 1px #cad4de;
  padding: 5px;
}
.positive {
  color: #49ba5d;
}
.negative {
  color: #fa1814;
}
.bold {
  font-weight: 900;
}
.current {
  display: block;
  width: 100%;
}
.clickable {
  cursor: pointer;
}
.right {
  float: right;
}
.icon {
  width: 1em;
  height: 1em;
}
.center {
  display:block;
  margin-left: auto;
  margin-right: auto;
}
.edit {
  display: none;
  width: 100%;
}
.edit input {
  width: 99%;
  margin: 0px;
}
.edit button {
  width: 100%;
}
input[type=number] {
  text-align:right;
}
</style>
